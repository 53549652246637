<template>
  <v-sheet>
    <cit-data-table
      ref="table"
      :appends="[
        'email_confirmed'
      ]"
      :headers="headers"
      resource-path="clients/company-clients"
    >

      <template v-slot:actions="{}">
        <new-button @click="openAddNew">Adauga client Companie noua</new-button>
      </template>

      <template v-slot:[`item.confirmed`]="{item}">

        <client-confirmed :value="item.email_confirmed"/>
        <div v-if="!item.email_confirmed">
          <v-btn text x-small @click="resendConfirmationEmail(item)">Retrimite mail de confirmare</v-btn>
        </div>

      </template>

      <template v-slot:[`item.created_at`]="{value}">
        {{ value | moment('DD-MM-YYYY HH:mm') }}
      </template>

      <template v-slot:[`item.agent`]="{item, value}">
        <client-agent-replacer :agent="value" :client="item" @changed="reload"/>
      </template>

      <template v-slot:[`item.discount`]="{item}">
        <client-discount-select :client="item" @changed="reload"/>
      </template>

      <template v-slot:[`item.interest_lists`]="{item}">
        <client-interest-select :client="item" @changed="reload"/>
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <view-button :to="{name:'view-client', params:{id:item.id}}" target="_self"/>
        <delete-button @delete="deleteUser(item)"/>
        <transform-button :customer-id="item.id"/>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import HelpIcon from '@/components/HelpIcon'
import LastContactedAt from '@/views/Clients/components/lastContactedAt'
import ClientAgentReplacer from '@/views/Clients/components/ClientAgentReplacer'
import ClientDiscountSelect from '@/views/Clients/components/ClientDiscountSelect'
import ClientAddExtraPoints from '@/views/Clients/components/ClientAddExtraPoints'
import ClientInterestSelect from '@/views/Clients/components/ClientInterestSelect'
import ClientMetaWishedProductsFromCit from '@/views/Clients/components/ClientMetaWishedProductsFromCit'
import ViewButton from '@/components/general-form/ViewButton'
import NewButton from '@/components/general-form/NewButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import ClientConfirmed from '@/views/Clients/components/ClientConfirmed'
import TransformButton from '@/components/general-form/TransformButton'

export default {
  name: 'CompanyClientsList',
  components: {
    TransformButton,
    ClientConfirmed,
    DeleteButton,
    NewButton,
    ViewButton,
    ClientMetaWishedProductsFromCit,
    ClientInterestSelect,
    ClientAddExtraPoints,
    ClientDiscountSelect,
    ClientAgentReplacer,
    LastContactedAt,
    HelpIcon,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          align: 'start',
          text: 'Companie',
          value: 'company.name',
          sortable: true,
          filterType: 'text',
          width: '200'
        },
        {
          align: 'start',
          text: 'Pers. Contact',
          value: 'full_name',
          sortable: true,
          filterType: 'text',
          width: '200'
        },
        {
          align: 'center',
          text: 'E Voucher',
          sortable: false,
          value: 'is_voucher',
          display: 'checkmarks',
          width: '90'
        },
        {
          align: 'start',
          text: 'Zona',
          value: 'zone.name',
          sortable: false,
          width: '150'
        },
        {
          align: 'start',
          text: 'Email',
          value: 'email',
          sortable: true,
          filterType: 'text',
          width: '250'
        },
        {
          text: 'Status Email',
          value: 'email_validation_status',
          filterType: 'simple-select',
          filterOptions: [
            {
              text: 'Deliverable',
              value: 'deliverable'
            },
            {
              text: 'Undeliverable',
              value: 'undeliverable'
            },
            {
              text: 'Unknown',
              value: 'unknown'
            },
            {
              text: 'Do Not Send',
              value: 'do_not_send'
            },
            {
              text: 'Catch All',
              value: 'catch_all'
            }
          ],
          width: '150'
        },
        {
          align: 'start',
          text: 'Telefon',
          value: 'phone',
          sortable: true,
          filterType: 'text',
          width: '250'
        },
        {
          align: 'start',
          text: 'Status',
          value: 'confirmed',
          sortable: false,
          filterType: 'simple-select',
          filterOptions: [
            {
              text: 'Confirmat',
              value: 'true'
            },
            {
              text: 'Neconfirmat',
              value: 'false'
            }
          ],
          width: '150'
        },
        {
          align: 'start',
          text: 'Data',
          value: 'created_at',
          filterType: 'date-range',
          sortable: true,
          width: '200'
        },
        {
          align: 'start',
          text: 'Agent',
          value: 'agent',
          filterType: 'agent',
          sortable: false,
          width: '200'
        },
        {
          align: 'start',
          text: 'Discount',
          value: 'discount',
          filterType: 'customer-discount',
          sortable: false,
          width: '120'
        },
        {
          text: 'Liste de interes',
          value: 'interest_lists',
          width: '200',
          sortable: false
        }
      ]
    }
  },
  methods: {
    reload () {
      this.$refs.table.loadItems()
    },
    openAddNew () {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: 'add-company-client',
        title: 'Adauga un cont nou de persoana juridica'
      })
    },
    resendConfirmationEmail (item) {
      this.$refs.table.loading = true
      this.$http.patch(`clients/${item.id}/send-email-notification`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
          this.reload()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.$refs.table.loading = false
        })
    },
    deleteUser (item) {
      this.$refs.table.loading = true
      this.$http.delete(`/clients/${item.id}`)
        .then(() => {
          this.reload()
          this.$vs.notify({
            title: 'Succes',
            text: 'Elementul a fost sters cu succes!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la stergere. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.$refs.table.loading = false
        })
    }
  },
  created () {
    this.$store.dispatch('agents/loadItems')
    this.$store.dispatch('loadClientDiscountList')
  }
}
</script>
